import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { Form, Button, Alert, Spinner, Container, Row, Col, Card } from 'react-bootstrap';

const UrlForm = () => {
  const apiUrl = process.env.REACT_APP_API_URL
  const webUrl = process.env.REACT_APP_WEB_URL
console.log({apiUrl},{webUrl})

  const [url, setUrl] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(true); // State for URL validation

  const isValidHttpUrl = (string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    const regex = /^(https?):\/\/([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(\/.*)?$/;
    return regex.test(string) && ['http:', 'https:'].includes(url.protocol);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isValidHttpUrl(url)) {
      setIsValidUrl(false);
      return;
    }

    setIsLoading(true);
    setIsValidUrl(true); // Reset the URL validation state

    try {
      // const templates = ['', 'index-flip.php', 'index2.php','index-th.php','index-redtape.php'];
      const templates = ['', 'index-flip.php', 'index2.php','index-th.php'];

      // Generate a random index based on the length of the array
      const randomTemplate = templates[Math.floor(Math.random() * templates.length)];
    
      const response = await fetch(`${apiUrl}/api/process-url`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url }),
      });

      const result = await response.json();
      console.log(result)

      const lines = result.output.split('\n');

      // Filter out empty strings to avoid issues with extra newlines
      const nonEmptyLines = lines.filter(line => line.trim() !== '');

      // Get the last non-empty line (the timestamp)
      const lastValue = nonEmptyLines[nonEmptyLines.length - 1];

      console.log('Last value (timestamp):', lastValue);
      window.location.href = `${webUrl}/${randomTemplate}?uuid=${lastValue}`;
    } catch (error) {
      setResponseMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center min-vh-100 bg-light">
      <Row className="w-100">
        <Col md={{ span: 6, offset: 3 }}>
          <Card className="shadow-lg p-4">
            <Card.Body>
              <h2 className="text-center mb-4" style={{ color: '#007bff', fontWeight: '600' }}>Redesign</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formUrl" className="mb-3">
                  <Form.Label style={{ fontWeight: '500' }}>Enter URL</Form.Label>
                  <Form.Control
                    type="url"
                    placeholder="https://example.com"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    isInvalid={!isValidUrl}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid URL with a domain extension.
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="w-100"
                  disabled={isLoading}
                  style={{ fontWeight: 'bold' }}
                >
                  {isLoading ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" /> Submitting...
                    </>
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Form>

              {responseMessage && (
                <Alert
                  className="mt-4"
                  variant={responseMessage.startsWith('Error') ? 'danger' : 'success'}
                >
                  {responseMessage}
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UrlForm;
