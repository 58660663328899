import React from 'react';
import Form from './Form.js';

const App = () => (
  <div className="App">
    <Form />
  </div>
);

export default App;
